import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import '../styles/privacy.scss';
import { Helmet } from 'react-helmet';

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Privacy</title>
        <meta name='description' content='HortPlus Privacy Policy' />
      </Helmet>
      <HeaderExpanded title={'Privacy Policy'} />
      <Container className='my-5'>
        <Row>
          <Col sm={6}>
            <p>
              We may collect personal information from you, including
              information about your:
            </p>
            <ul>
              <li>name</li>
              <li>contact information</li>
            </ul>
            <p>
              We collect your personal information in order to: help provide a
              better online service You have the right to ask for a copy of any
              personal information we hold about you, and to ask for it to be
              corrected if you think it is wrong.
            </p>
            <p>
              If you'd like to ask for a copy of your information, or to have it
              corrected, please contact us at support@hortplus.com.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Privacy;
